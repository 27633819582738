import gql from "graphql-tag";

// Note: These graphQL queries need to be made as functions as seen below,
// due to the apolloMockBuilder being able to pass in arguments to the query as needed.

export const ADD_PRODUCT = () => {
  return `mutation AddProduct($input: AddProductInput) {
    addProduct(input: $input) {
      id
    }
  }`;
};

export const ADD_SERVER = () => `
  mutation AddServer($input: AddServer!) {
    addServer(input: $input) {
      id
    }
  }
`;

export const GET_CHANGE_BY_ID = () => `query Change($changeId: ID) {
  change(id: $changeId) {
    afterChange
    beforeChange
  }
}`;

export const GET_CHANGESETS_BY_TARGET_ID = ({ targetId }) => {
  return `query Changesets($limit: Int, $offset: Int, $sort: [SortOptionInput]) {
  changesetsWithCount(
    limit: $limit
    offset: $offset
    sort: $sort
    filter: {changes_targetId_is: "${targetId}"}
  ) {
    changesets {
      id
      name
      description
      status
      scheduledAt
      changes {
        id
        targetId
        createdBy {
          firstName
          lastName
          email
        }
      }
    }
    totalRows
  }
}`;
};

export const GET_DEPARTMENTS = () => {
  return `query Departments {
    departmentsWithCount {
      departments {
        id
        description
        frontOfHouse
      }
    }
  }`;
};

export const GET_DETAILED_ORDERS = () => {
  return `query DetailedOrdersWithCount($limit: Int, $offset: Int, $filter: DetailedOrderFilterInput, $sort: [SortOptionInput]) {
    detailedOrdersWithCount(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      detailedOrders {
        id
        displayReference
        externalReference
        site {
          name
        }
        type
        status
        errorReason
        createdAt
        createdBy {
          email
        }
        expectedAt
        itemGroups {
          items {
            modifiers {
              code
              discounted
              price
              quantity
            }
            id
            code
            discounted
            price
            quantity
          }
          serviceCharge
        }
      }
      totalRows
    }
  }`;
};

export const GET_PICKED_AND_ENABLED_FOR_PRODUCT = () => {
  return `query PickedAndEnabledForProduct(
    $productId: ID
    $siteId: ID
  ) {
    product(id: $productId, siteId: $siteId) {
      id
      enabled
      picked
    }
  }`;
};

export const GET_PRICE_LEVEL_SETUPS = () => {
  return `query PriceLevelSetupsWithCount($filter: PriceLevelSetupFilterInput) {
    priceLevelSetupsWithCount(filter: $filter) {
      priceLevelSetups {
        id
        enabled
        salesAreas {
          id
        }
        description
        day
        startTime
        endTime
        priceLevel
      }
    }
  }`;
};

export const UPDATE_PRICE_LEVEL_SETUP = () => {
  return `mutation UpdatePriceLevelSetup($input: UpdatePriceLevelSetup!) {
    updatePriceLevelSetup(input: $input) {
      id
    }
  }`;
};

export const ADD_PRICE_LEVEL_SETUP = () => {
  return `mutation AddPriceLevelSetup($input: AddPriceLevelSetup!) {
    addPriceLevelSetup(input: $input) {
      id
    }
  }`;
};

export const GET_PRODUCT_BY_ID = () => {
  return `query Product(
    $productId: ID
    $siteId: ID
  ) {
    product(id: $productId, siteId: $siteId) {
      id
      POSId
      maxMod
      name
      price
      prices {
        id
        level
        price
        productOption {
          id
        }
      }
      statuses {
        enabled
        name
      }
      tillProductName
      vatRateCode
    }
  }`;
};

export const GET_PRODUCT_OPTION_GROUPS_BY_PRODUCT_ID = () => {
  return `query ProductOptionGroupsByProductId(
    $productId: ID
    $siteId: ID
  ) {
    product(id: $productId, siteId: $siteId) {
      id
      productOptionGroups {
        id
        name
        createdAt
      }
    }
  }`;
};

export const GET_PRODUCT_BY_ID_TAGS = () => {
  return `query Product(
    $productId: ID
    $siteId: ID
  ) {
    product(id: $productId, siteId: $siteId) {
      id
      tags {
        id
        name
        POSId
        type
        createdAt
      }
    }
  }`;
};

export const GET_PRODUCT_BY_ID_MODIFER_GROUPS = () => {
  return `query Product(
    $productId: ID
    $siteId: ID
  ) {
    product(id: $productId, siteId: $siteId) {
      id
      modifierGroups {
        modifierGroup {
          id
          POSClassId
          name
        }
        required
      }
    }
  }`;
};

export const GET_PRODUCTS_WITH_COUNT = () => {
  return `query Products(
    $limit: Int
    $offset: Int
    $sort: [SortOptionInput]
    $filter: ProductFilterInput
  ) {
    productsWithCount(limit: $limit, offset: $offset, sort: $sort, filter: $filter ) {
      products {
        id
        name
        POSId
        price
        createdAt
        sites {
          id
        }
      }
      totalRows
    }
  }`;
};

export const GET_PRODUCT_OPTIONS_WITH_COUNT = () => {
  return `query ProductOptions(
    $limit: Int
    $offset: Int
    $sort: [SortOptionInput]
    $filter: ProductOptionFilterInput
  ) {
    productOptionsWithCount(limit: $limit, offset: $offset, sort: $sort, filter: $filter ) {
      productOptions {
        id
        name
        POSId
        createdAt
      }
      totalRows
    }
  }`;
};

export const GET_PRODUCT_OPTIONS = () => {
  return `query ProductOptions($limit: Int, $offset: Int, $sort: [SortOptionInput]) {
  productOptions(limit: $limit, offset: $offset, sort: $sort) {
    id
    name
  }
}`;
};

export const GET_PRODUCT_OPTION_GROUP_BY_ID = () => {
  return `query ProductOptionGroup($productOptionGroupId: ID!) {
    productOptionGroup(id: $productOptionGroupId) {
      name
      productOptions {
        id
      }
    }
  }`;
};

export const GET_PRODUCT_OPTION_GROUPS_WITH_COUNT = () => {
  return `query ProductOptionGroups(
    $limit: Int
    $offset: Int
    $sort: [SortOptionInput]
    $filter: ProductOptionGroupFilterInput
  ) {
    productOptionGroupsWithCount(limit: $limit, offset: $offset, sort: $sort, filter: $filter ) {
      productOptionGroups {
        id
        name
        createdAt
      }
      totalRows
    }
  }`;
};

export const GET_PRODUCT_OPTIONS_BY_PRODUCT_ID = () => {
  return `query getProductOptions($productId: ID) {
    product(id: $productId) {
      productOptionGroups {
        productOptions {
          id
          name
        }
      }
    }
  }`;
};

export const GET_MODIFIER_GROUPS_WITH_COUNT = () => {
  return `query ModifierGroups(
    $limit: Int,
    $offset: Int,
    $filter: ModifierGroupFilterInput,
    $sort: [SortOptionInput]
  ) {
    modifierGroupsWithCount(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
      modifierGroups {
        id
        POSClassId
        name
      }
      totalRows
    }
}`;
};

export const GET_MODIFIER_GROUPS = () => {
  return `query ModifierGroups($limit: Int, $offset: Int, $sort: [SortOptionInput]) {
    modifierGroups(limit: $limit, offset: $offset, sort: $sort) {
      id
      name
    }
  }`;
};

export const GET_MODIFIER_GROUP_BY_PRODUCT_ID = () => {
  return `query ModifierGroupByProductId(
    $productId: ID
    $siteId: ID
  ) {
    product(id: $productId, siteId: $siteId) {
      id
      modifierGroup {
        id
        name
        POSClassId
      }
    }
  }`;
};

export const GET_SALES_AREAS = () => {
  return `query SalesAreas($sort: [SortOptionInput]) {
    salesAreasWithCount(sort: $sort) {
      salesAreas {
        id
        description
        serviceContributionPercentage
      }
    }
  }`;
};

export const ADD_SALES_AREA = () =>
  `mutation AddSalesArea($input: AddSalesArea!) {
    addSalesArea(input: $input) {
      id
    }
  }`;

export const UPDATE_SALES_AREA = () =>
  `mutation UpdateSalesArea($input: UpdateSalesArea!) {
    updateSalesArea(input: $input) {
      id
    }
  }`;

export const GET_SERVER_ROLES = () => `
  query ServerRoles {
    serverRolesWithCount {
      serverRoles {
        id
        description
      }
    }
  }
`;

export const GET_SERVERS_WITH_COUNT = () => `
  query GetServersWithCount(
    $limit: Int
    $offset: Int
    $sort: [SortOptionInput]
    $filter: ServerFilterInput) {
    serversWithCount(
      limit: $limit
      offset: $offset
      sort: $sort
      filter: $filter
    ) {
      servers {
        id
        name
        logonCode
        userLevel {
          id
          description
        }
        authCode
        authLevel {
          id
          description
        }
        isTraining
        hourlyCost
        department {
          id
          description
        }
        isAutomated
      }
      totalRows
    }
  }
`;

export const GET_SETTINGS = fields => {
  return `query Settings {
    settings {
      ${fields}
    }
  }`;
};

export const GET_TRANSACTION_REASONS = () => {
  return `query TransactionReasons {
    transactionReasonsWithCount {
      transactionReasons {
        id
        description
      }
    }
  }`;
};

export const ADD_TRANSACTION_REASON = () => {
  return `mutation AddTransactionReason($description: String!) {
    addTransactionReason(description: $description) {
      id
    }
  }`;
};

export const UPDATE_TRANSACTION_REASON = () => {
  return `mutation UpdateTransactionReason($input: UpdateTransactionReasonInput!) {
    updateTransactionReason(input: $input) {
      id
    }
  }`;
};

export const ADD_DEPARTMENT = () => {
  return `mutation AddDepartment($input: AddDepartment!) {
    addDepartment(input: $input) {
      id
    }
  }`;
};

export const UPDATE_DEPARTMENT = () => {
  return `mutation UpdateDepartment($input: UpdateDepartment!) {
    updateDepartment(input: $input) {
      id
    }
  }`;
};

export const ADD_TILL = () => {
  return `mutation AddTill($input: AddTillInput!) {
    addTill(input: $input) {
      id
    }
  }`;
};

export const UPDATE_TILL = () => {
  return `mutation UpdateTill($input: UpdateTillInput!) {
    updateTill(input: $input) {
      id
    }
  }`;
};

export const GET_TILLS = () => {
  return `query Tills($filter: TillFilterInput) {
    tills(filter: $filter) {
      id
      tillId
      tillName
      canBeMaster
      apiServer
      apiTill
      takeOut
      pdqTerminal
      rabbitMq
      logonLength
      machineId
      licence
      days
      oposPort
      scannerName
    }
  }`;
};

export const GET_TILL_BY_ID = () => {
  return `query Till($tillId: ID!) {
  till(id: $tillId) {
    id
    siteId {
      id
      name
    }
    tillId
    tillName
    logonLength
    machineId
    licence
    days
    oposPort
    scannerName
    canBeMaster
    apiServer
    apiTill
    takeOut
    pdqTerminal
    rabbitMq
  }
}`;
};

export const GET_TILL_CONFIG_VALUES_BY_ID = () => {
  return `query TillConfigValues($tillId: ID!) {
    tillConfigValues(tillId: $tillId) {
      configurationId
      groupName
      name
      value
    }
  }`;
};

export const UPDATE_TILL_CONFIG_VALUE = () => {
  return `mutation UpdateTillConfigValue($input: UpdateTillConfigInput!) {
    updateTillConfigValue(input: $input) {
      configurationId
    }
  }`;
};

export const GET_VAT_RATES = () => {
  return `query VatRates($limit: Int, $offset: Int, $sort: [SortOptionInput]) {
  vatRates(limit: $limit, offset: $offset, sort: $sort) {
    id
    code
  }
}`;
};

export const ADD_PRODUCT_OPTION_GROUP = () => {
  return `mutation AddProductOptionGroup($input: AddProductOptionGroupInput) {
    addProductOptionGroup(input: $input) {
      id
    }
  }`;
};

export const UPDATE_PRODUCT_OPTION_GROUP = () => {
  return `mutation UpdateProductOptionGroup($input: UpdateProductOptionGroupInput) {
    updateProductOptionGroup(input: $input) {
      id
    }
  }`;
};

export const ADD_PRODUCT_OPTIONS_TO_GROUP = () => {
  return `mutation AddProductOptionsToGroup($input: ChangeProductOptionsInGroupInput) {
        addProductOptionsToGroup(input: $input) {
          id
        }
      }`;
};

export const REMOVE_PRODUCT_OPTIONS_FROM_GROUP = () => {
  return `mutation RemoveProductOptionsFromGroup($input: ChangeProductOptionsInGroupInput) {
        removeProductOptionsFromGroup(input: $input) {
          id
        }
      }`;
};

export const GET_PRODUCT_TAGS_WITH_COUNT = () => {
  return `query ProductTags(
    $limit: Int
    $offset: Int
    $sort: [SortOptionInput]
    $filter: ProductTagFilterInput
    $onlyShowTagsWithProducts: Boolean
  ) {
    productTagsWithCount(limit: $limit, offset: $offset, sort: $sort, filter: $filter, onlyShowTagsWithProducts: $onlyShowTagsWithProducts ) {
      productTags {
        id
        name
        POSId
        type
        createdAt
      }
      totalRows
    }
  }`;
};

export const GET_PRODUCT_TAGS_WITH_PARENT = () => {
  return `query ProductTagsWithParent($filter: ProductTagFilterInput) {
    productTagsWithCount(filter: $filter) {
      productTags {
        id
        POSId
        name
        parentTag {
          id
          name
        }
        typeSettings {
          subgroupSettings {
            accommodationIncome
            priorityPrintGroupId
            siteManaged
          }
        }
      }
    }
  }`;
};

export const UPDATE_PRODUCT_TAG = () => {
  return `mutation UpdateProductTag($input: UpdateProductTagInput) {
    updateProductTag(input: $input) {
      id
    }
  }`;
};

export const ADD_PRODUCT_TAG = () => {
  return `mutation AddProductTag($input: AddProductTagInput) {
    addProductTag(input: $input) {
      id
    }
  }`;
};

export const GET_PRODUCT_TAGS = () => {
  return `query ProductTags(
    $limit: Int
    $offset: Int
    $filter: ProductTagFilterInput
    $sort: [SortOptionInput]
  ) {
    productTags(limit: $limit, offset: $offset, filter: $filter, sort: $sort) {
        id
        name
        type
    }
  }`;
};

export const GET_SITES = () => {
  return `query Sites($offset: Int, $limit: Int, $sort: [SortOptionInput]) {
    sites(offset: $offset, limit: $limit, sort: $sort) {
      id
      name
    }
  }`;
};

export const GET_FLAT_PRODUCTS_WITH_COUNT = () => {
  return `query FlatProducts($limit: Int, $offset: Int, $sort: [SortOptionInput], $siteId: ID) {
  flatProducts(limit: $limit, offset: $offset, sort: $sort, siteId: $siteId) {
    flatProducts {
      id
      productCode
      description
      tillDescription
      subGroup
      productGroup
      modifierOf
      price1
      price2
      price3
      price4
      price5
      price6
      modifierGroup1
      modifierGroup2
      modifierGroup3
      modifierGroup4
      modifierGroup5
      modifierGroup6
      maxMod
      picked
      priority
      rrp
      costPrice
      vatCode
      negative_price
      red_print
      open_description
      open_price
      modifier
      promotional_trigger
      receipt_suppress
    }
    totalRows
  }
}`;
};

export const DELETE_CHANGE = () => {
  return `mutation DeleteChange($deleteChangeId: ID) {
  deleteChange(id: $deleteChangeId) {
    id
    changeset {
      id
    }
    recordActive
    deletedAt
    deletedBy {
      email
    }
  }
}`;
};

const regenerateDefaultFields = `
justEatCredentials {
  apiKey
  hmacKey
}`;

export const REGENERATE_SECRET = (fetchFields = regenerateDefaultFields) => {
  return `mutation GenerateSecret($input: GenerateSecretInput!) {
    generateSecret(input: $input) {
      ${fetchFields}
    }
  }`;
};

export const UPDATE_PRODUCT = () => {
  return `mutation UpdateProduct($input: UpdateProductInput) {
    updateProduct(input: $input) {
      id
    }
  }`;
};

export const UPDATE_SERVER = () => `
  mutation UpdateServer($input: UpdateServer!) {
    updateServer(input: $input) {
      id
    }
  }
`;

export const UPDATE_SETTINGS = fields => {
  return `mutation UpdateSettings($input: UpdateSettingsInput!) {
    updateSettings(input: $input) {
      ${fields}
    }
  }`;
};

export const ADD_PDQ_PAYMENT_TYPE = () => {
  return `mutation AddPdqPaymentType($input: AddPDQPaymentType!) {
    addPdqPaymentType(input: $input) {
      id
      description
      paymentType {
        id
        name
      }
    }
  }`;
};

export const GET_PDQ_PAYMENT_TYPES = () => {
  return `query PdqPaymentTypesWithCount {
    pdqPaymentTypesWithCount {
      pdqPaymentTypes {
        id
        description
        paymentType {
          id
          name
        }
      }
    }
  }`;
};

export const GET_PDQ_PAYMENT_TYPE_BY_ID = () => {
  return `query PdqPaymentType($pdqPaymentTypeId: ID!) {
    pdqPaymentType(id: $pdqPaymentTypeId) {
      id
      description
      paymentType {
        id
        name
      }
    }
  }`;
};

export const UPDATE_PDQ_PAYMENT_TYPE = () => {
  return `mutation UpdatePdqPaymentType($input: UpdatePDQPaymentType!) {
    updatePdqPaymentType(input: $input) {
      id
      description
      paymentType {
        id
        name
      }
    }
  }`;
};

export const GET_PAYMENT_TYPES = () => {
  return `query PaymentTypes {
    paymentTypes {
      id
      name
    }
  }`;
};

export const GET_PRINT_TEXTS = () => {
  return `query GetPrintTexts($site: ID, $salesArea: ID) {
    getPrintTexts(site: $site, salesArea: $salesArea) {
      id
      lineNumber
      textContent
      type
      doubleWidth
      doubleHeight
      salesArea {
        id
      }
      site {
        id
      }
    }
  }`;
};

export const ADD_PRINT_TEXT = () => {
  return `mutation AddPrintText($input: [AddPrintText]!) {
    addPrintText(input: $input) {
      id
    }
  }`;
};

export const UPDATE_PRINT_TEXT = () => {
  return `mutation UpdatePrintText($input: UpdatePrintText!) {
    updatePrintText(input: $input) {
      id
    }
  }`;
};

export const DELETE_PRINT_TEXT = () => {
  return `mutation DeletePrintText($input: DeletePrintText!) {
    deletePrintText(input: $input)
  }`;
};

export const apolloQueries = {
  ADD_DEPARTMENT,
  GET_PAYMENT_TYPES,
  ADD_PDQ_PAYMENT_TYPE,
  ADD_PRICE_LEVEL_SETUP,
  ADD_PRINT_TEXT,
  ADD_PRODUCT_OPTION_GROUP,
  ADD_PRODUCT_OPTIONS_TO_GROUP,
  ADD_PRODUCT,
  ADD_PRODUCT_TAG,
  ADD_SALES_AREA,
  ADD_SERVER,
  ADD_TILL,
  ADD_TRANSACTION_REASON,
  DELETE_CHANGE,
  DELETE_PRINT_TEXT,
  GET_CHANGE_BY_ID,
  GET_CHANGESETS_BY_TARGET_ID,
  GET_DEPARTMENTS,
  GET_DETAILED_ORDERS,
  GET_FLAT_PRODUCTS_WITH_COUNT,
  GET_MODIFIER_GROUP_BY_PRODUCT_ID,
  GET_MODIFIER_GROUPS_WITH_COUNT,
  GET_MODIFIER_GROUPS,
  GET_PDQ_PAYMENT_TYPES,
  GET_PDQ_PAYMENT_TYPE_BY_ID,
  GET_PICKED_AND_ENABLED_FOR_PRODUCT,
  GET_PRICE_LEVEL_SETUPS,
  GET_PRINT_TEXTS,
  GET_PRODUCT_BY_ID_MODIFER_GROUPS,
  GET_PRODUCT_BY_ID_TAGS,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_OPTION_GROUP_BY_ID,
  GET_PRODUCT_OPTIONS_BY_PRODUCT_ID,
  GET_PRODUCT_OPTION_GROUPS_BY_PRODUCT_ID,
  GET_PRODUCT_OPTION_GROUPS_WITH_COUNT,
  GET_PRODUCT_OPTIONS_WITH_COUNT,
  GET_PRODUCT_OPTIONS,
  GET_PRODUCT_TAGS_WITH_COUNT,
  GET_PRODUCT_TAGS,
  GET_PRODUCT_TAGS_WITH_PARENT,
  GET_PRODUCTS_WITH_COUNT,
  GET_SALES_AREAS,
  GET_SERVER_ROLES,
  GET_SERVERS_WITH_COUNT,
  GET_SITES,
  GET_TILLS,
  GET_TILL_BY_ID,
  GET_TILL_CONFIG_VALUES_BY_ID,
  GET_TRANSACTION_REASONS,
  GET_VAT_RATES,
  REGENERATE_SECRET,
  REMOVE_PRODUCT_OPTIONS_FROM_GROUP,
  UPDATE_DEPARTMENT,
  UPDATE_PDQ_PAYMENT_TYPE,
  UPDATE_PRICE_LEVEL_SETUP,
  UPDATE_PRINT_TEXT,
  UPDATE_PRODUCT_OPTION_GROUP,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_TAG,
  UPDATE_SALES_AREA,
  UPDATE_SERVER,
  UPDATE_TILL,
  UPDATE_TILL_CONFIG_VALUE,
  UPDATE_TRANSACTION_REASON,
};

// TODO: Should this be available to test code only?

// This can definitely be refactored and improved to use a more dynamic approach in the future.
// Perhaps including a way to generate mock results based on the query, unless defined.
export const apolloMockBuilder = ({
  errors = null,
  customQuery = null, // When testing, we might want to input a dynamic query to check the API returns fields as expected.
  maxUsageCount = 1,
  query = null,
  queryArgs,
  results = {},
  variables = null,
  variableMatcher = () => true,
}) => {
  return {
    maxUsageCount,
    queryName: customQuery ? "customQuery" : query,
    request: {
      query: customQuery
        ? gql(customQuery)
        : gql(apolloQueries[query](queryArgs)),
      ...(variables && { variables }),
    },
    ...(variableMatcher && { variableMatcher }),
    result: errors
      ? { errors }
      : {
          data: results,
        },
  };
};
