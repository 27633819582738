import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { observer, inject, PropTypes as MobXPropTypes } from "mobx-react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { styled } from "@mui/material/styles";
import { useSnackbar } from "notistack";
import { DataGrid, useGridApiRef } from "@mui/x-data-grid";

import CustomGridRow from "../../../components/Datagrid/CustomGridRow";

import { GET_SALES_AREAS } from "../../../helpers/apollo/utils";

const StyledBox = styled("div")(({ theme }) => ({
  width: "100%",
  "& .Mui-error": {
    backgroundColor: `rgb(126,10,15, ${theme.palette.mode === "dark" ? 0 : 0.1})`,
    color: theme.palette.error.main,
  },
}));

const getColumns = () => [
  {
    field: "description",
    headerName: "Description",
    type: "text",
    editable: false,
    minWidth: 150,
  },
];

const SalesAreasDatatable = ({ appStore, handleRowClick }) => {
  const apiRef = useGridApiRef();
  const { enqueueSnackbar } = useSnackbar();

  const [columns, setColumns] = useState([]);
  const [tableData, setTableData] = useState([]);

  const errorSnackbar = errorMsg => {
    enqueueSnackbar(`Error: ${errorMsg}`, {
      variant: "error",
      SnackbarProps: {
        "data-testid": "sales-areas-error-snackbar",
      },
    });
  };

  useEffect(() => {
    // To avoid a setTimeout we have tested subscribing to the 'stateChange' event,
    // however, this causes cell jumping and breaks responsively and therefore
    // have resorted to a timeout, which is also used in the MUI docs for this feature.
    setTimeout(() => {
      apiRef?.current?.autosizeColumns({
        includeHeaders: true,
        includeOutliers: true,
      });
    }, 10);
  }, [tableData]);

  const { loading: loadingSalesAreas } = useQuery(gql(GET_SALES_AREAS()), {
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      const salesAreas = data?.salesAreasWithCount?.salesAreas;

      if (salesAreas) {
        const defaultSalesArea = { id: "default", description: "Default" };

        setTableData([defaultSalesArea, ...salesAreas]);
      }

      setColumns(getColumns());

      appStore.setLoading(false);
    },
    onError: error => {
      appStore.setLoading(false);
      errorSnackbar(error.message);
    },
  });

  return (
    <Container maxWidth={false}>
      <Grid container rowSpacing={3}>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          alignItems="center"
          my={3}
        >
          <Grid item>
            <Typography variant="h2" data-testid="main-header">
              Sales Areas
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <StyledBox>
            <DataGrid
              apiRef={apiRef}
              columns={columns}
              editMode="row"
              loading={loadingSalesAreas}
              rows={tableData}
              slots={{
                row: CustomGridRow,
              }}
              onRowClick={handleRowClick}
              onProcessRowUpdateError={errorSnackbar}
            />
          </StyledBox>
        </Grid>
      </Grid>
    </Container>
  );
};

SalesAreasDatatable.propTypes = {
  appStore: MobXPropTypes.objectOrObservableObject.isRequired,
  handleRowClick: PropTypes.func.isRequired,
};

export default inject("appStore")(observer(SalesAreasDatatable));
